import axios from "axios";
import {authHeader} from '@/_helpers';
import config from 'config';
import store from "../_store/store";

export const trackerService = {
  getPositions,
  getDatalogs,
  getTracker,
  addTracker,
  updateTracker,
  getTrackerGroups,
  getTrackerPending,
  addTrackerGroup,
  updateTrackerGroup,
  deleteTrackerGroup,
  addTrackerGroupConnection,
  delTrackerGroupConnection,
  getTrackerSettings,
  addTrackerSettings,
  addTrackerSettingsBuilder,
  addTrackerSettingChange,
  getTrackerSettingChange,
  getTrackerProxyServer,
  updateTrackerProxyServer,
  addTrackerProxyServer,
  delTrackerProxyServer,
  downloadKml,
  getHttpDataDebugTable
};

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    //if(error.response.data.msg === "Token has expired" || error.response.data.msg === "Missing Authorization Header"){
    store.dispatch('account/logout').then(() => {
      console.log("logged out, need to redirect");
      location.reload(true);
      //router.push('/login');
    });
    return error;
    //}
  } else {
    return Promise.reject(error);
  }
});

function axiosConfig(merge) {
  return {
    headers: authHeader(),
    ...merge
  }
}



function getTracker() {
  return axios.get(config.apiUrl + `/tracker/tracker/`, axiosConfig());
}

function addTracker(tracker) {
  return axios.put(config.apiUrl + "/tracker/tracker/", tracker, axiosConfig());
}

function updateTracker(tracker) {
  return axios.put(config.apiUrl + `/tracker/tracker/${tracker.id}`, tracker, axiosConfig());
}

function getTrackerPending() {
  return axios.get(config.apiUrl + `/tracker/tracker/pending`, axiosConfig());
}

function getTrackerGroups() {
  return axios.get(config.apiUrl + `/tracker/group/`, axiosConfig());
}

function addTrackerGroup(group) {
  return axios.put(config.apiUrl + "/tracker/group/", group, axiosConfig());
}

function updateTrackerGroup(group) {
  return axios.put(config.apiUrl + `/tracker/group/${group.id}`, group, axiosConfig());
}

function deleteTrackerGroup(group) {
  return axios.delete(config.apiUrl + `/tracker/group/${group.id}`, axiosConfig());
}

function addTrackerGroupConnection(groupId, trackerId) {
  return axios.put(  config.apiUrl+`/tracker/group/${groupId}/${trackerId}`, {}, axiosConfig());
}

function delTrackerGroupConnection(groupId, trackerId) {
  return axios.delete(  config.apiUrl+`/tracker/group/${groupId}/${trackerId}`, axiosConfig());
}

function getTrackerSettings() {
  return axios.get(config.apiUrl + `/tracker/setting/`, axiosConfig());
}

function addTrackerSettings(settings) {
  return axios.put(config.apiUrl + "/tracker/setting/", settings, axiosConfig());
}

function addTrackerSettingsBuilder(buildconfig) {
  return axios.put(config.apiUrl + "/tracker/setting/build/", buildconfig, axiosConfig());
}

function addTrackerSettingChange(imei, crc) {
  return axios.put(config.apiUrl + `/tracker/settingchange/${imei}/${crc}`, {}, axiosConfig());
}

function getTrackerSettingChange() {
  return axios.get(config.apiUrl + `/tracker/settingchange/`, axiosConfig());
}

function getDatalogs(imei=false) {
  if(imei){
    return axios.get(config.apiUrl + `/tracker/datalog/${imei}`, axiosConfig());
  }
  else {
    return axios.get(config.apiUrl + `/tracker/datalog`, axiosConfig());
  }
}

function getPositions(imei=false) {
  if(imei){
    return axios.get(config.apiUrl + `/tracker/positions/${imei}`, axiosConfig());
  }
  else {
    return axios.get(config.apiUrl + `/tracker/positions`, axiosConfig());
  }
}

function getTrackerProxyServer() {
  return axios.get(config.apiUrl + `/tracker/proxyserver/`, axiosConfig());
}

function updateTrackerProxyServer(server) {
  return axios.put(config.apiUrl + `/tracker/proxyserver/${server.id}`, server, axiosConfig());
}

function addTrackerProxyServer(server) {
  return axios.post(config.apiUrl + "/tracker/proxyserver/", server, axiosConfig());
}

function delTrackerProxyServer(server) {
  return axios.delete(config.apiUrl+`/tracker/proxyserver/${server.id}`, axiosConfig());
}

function downloadKml(tracker_id, progressCallback) {
  return axios.get(config.apiUrl+`/tracker/export_positions/${tracker_id}`, axiosConfig({
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / parseInt(progressEvent.currentTarget.getResponseHeader('X-Actual-Content-Length'))
      );
      progressCallback(percentCompleted);
    },
  }));
}

function getHttpDataDebugTable(datahttp){
  return axios.post(config.apiUrl+`/tracker/datahttp_debug/`,{"datahttp": datahttp}, axiosConfig());
}
