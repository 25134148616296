export const alerts = {
    state: {
        toasts: []
    },
    mutations: {
        addToast(state, payload) {
            state.toasts.push(payload)
        },
    },
    getters: {
        getToasts: state => state.toasts,
    },
    actions: {
        async addToast(state, payload) {
            state.commit('addToast', payload);
        },
    }
};
