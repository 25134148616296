<template>
  <div>
    <CToaster :autohide="5000">
      <template v-for="(toast, key) in toaster">
        <CToast :key="'toast' + key" :show="true" :header="toast.header" :color="toast.color">
          {{toast.text}}
        </CToast>
      </template>
    </CToaster>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed:{
    toaster: function() {
      return this.$store.getters.getToasts
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
