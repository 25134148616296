//import config from 'config';
import {authHeader} from '../_helpers';
import config from 'config';
import axios from "axios";
import jwt_decode from "jwt-decode";
import store from "@/_store/store";


export const userService = {
  login,
  logout,
  checkLogin,
  getPermissionLevel,
  register,
  getAll,
  update,
  delete: _delete
};


axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    //if(error.response.data.msg === "Token has expired" || error.response.data.msg === "Missing Authorization Header"){
    store.dispatch('account/logout').then(() => {
      console.log("logged out, need to redirect");
      location.reload(true);
      //router.push('/login');
    });
    return error;
    //}
  } else {
    return Promise.reject(error);
  }
});

function axiosConfig(merge) {
  return {
    headers: authHeader(),
    ...merge
  }
}

function login(username, password) {
  return axios.post(config.apiUrl + '/user/login', JSON.stringify({username, password}))
    .then(response => {
      // console.log(response.data)
      // login successful if there's a jwt token in the response
      if (response.data.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        let decoded = jwt_decode(response.data.access_token);
        let user = {
          "jwt": response.data.access_token,
          "username": decoded.username,
          "permissionlevel": decoded.permissionlevel,
        }
        localStorage.setItem('user', JSON.stringify(user));
        return user;
      }
      return {"error": "not successful"}
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function checkLogin() {
  const loggedIn = localStorage.getItem('user');
  return loggedIn;
  //var decoded = jwt_decode(loggedIn);
  //console.log(decoded);
}

function getPermissionLevel() {
  const user = JSON.parse(localStorage.getItem('user'));
  return user ? parseInt(user.permissionlevel) : 0;
}

function register(user) {
  return axios.post(config.apiUrl + "/user/register", user, axiosConfig());
}

function getAll() {
  return axios.get(config.apiUrl + "/user/user", axiosConfig());
}

function update(user) {
  return axios.put(config.apiUrl + "/user/update", user, axiosConfig());
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return axios.delete(config.apiUrl + `/user/delete/${id}`, axiosConfig());
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
