import {twitterService} from "@/_services/twitter.service";

export const twitter = {
    state: {
        currentTwitterStats: {
            num_tweets: 0,
            num_deleted_tweets: 0,
            num_users: 0,
        },
        currentUsers: [],
        currentUsersLoading: true,
        currentUsersNeedReloading: true,
        currentTweets: [],
        currentTweetsLoading: true,
        currentTweetsFilter: {
            include_retweets: 0,
            deleted: 0,
            query: null,
            limit: 2000,
            page: null,
            user_ids: null,
            tags: null
        },
        currentTweetsMeta: {
            count:0,
            totalCount:0,
            allTags:[],
        },
        currentTags: []
    },
    mutations: {
        setCurrentTwitterStats(state, payload) {
            state.currentTwitterStats = payload
        },
        setCurrentTwitterUsers(state, payload) {
            state.currentUsers = payload;
            state.currentUsersLoading = false
        },
        activateCurrentUsersLoading(state) {
            state.currentUsersLoading = true
        },
        setCurrentTwitterTweets(state, payload) {
            payload.forEach(tweet => {
                if(tweet.added != null) tweet.added = new Date(tweet.added);
                if(tweet.checked != null) tweet.checked = new Date(tweet.checked);
                if(tweet.created != null) tweet.created = new Date(tweet.created);
                if(tweet.deleted != null) tweet.deleted = new Date(tweet.deleted);
            });
            state.currentTweets = payload;
            state.currentTweetsLoading = false
        },
        setCurrentTwitterTweetsMeta(state, payload) {
            state.currentTweetsMeta.count = payload.count;
            state.currentTweetsMeta.totalCount = payload.total_count;
            state.currentTweetsMeta.allTags = payload.all_tags;
        },
        setCurrentTweetsFilter(state, payload){
            if("include_retweets" in payload) state.currentTweetsFilter.include_retweets = payload.include_retweets;
            if("deleted" in payload) state.currentTweetsFilter.deleted = payload.deleted;
            if("user_ids" in payload) state.currentTweetsFilter.user_ids = payload.user_ids;
            if("query" in payload) state.currentTweetsFilter.query = payload.query;
            if("limit" in payload) state.currentTweetsFilter.limit = payload.limit;
            if("page" in payload) state.currentTweetsFilter.page = payload.page;
            if("tags" in payload) state.currentTweetsFilter.tags = payload.tags;
        },
        activateCurrentTweetsLoading(state) {
            state.currentTweetsLoading = true
        },
        setCurrentTwitterTags(state, payload){
            state.currentTags = payload;
        },
        setCurrentUsersNeedReloading(state, payload){
            state.currentUsersNeedReloading = payload;
        }
    },
    getters: {
        getTwitterStats: state => state.currentTwitterStats,
        getCurrentTwitterUsers: state => state.currentUsers,
        getCurrentUsersLoading: state => state.currentUsersLoading,
        getCurrentUsersNeedReloading: state => state.currentUsersNeedReloading,
        getCurrentTwitterTweets: state => state.currentTweets,
        getCurrentTwitterTweetsMeta: state => state.currentTweetsMeta,
        getCurrentTweetsLoading: state => state.currentTweetsLoading,
        getCurrentTweetsFilter: state => state.currentTweetsFilter,
        getCurrentTwitterTags: state => state.currentTags,
    },
    actions: {
        async requestCurrentTwitterStats(state) {
            return twitterService.getStats().then(res => {
                state.commit("setCurrentTwitterStats", res.data);
            });
        },
        async requestCurrentTwitterUsers(state) {
            state.commit("activateCurrentUsersLoading");
            return twitterService.getUsers().then(res => {
                state.commit("setCurrentTwitterUsers", res.data.users);
                state.commit("setCurrentUsersNeedReloading", false);
            });
        },
        async requestCurrentTwitterTweets(state) {
            state.commit("activateCurrentTweetsLoading");
            return twitterService.getTweets(state.getters.getCurrentTweetsFilter).then(res => {
                state.commit("setCurrentTwitterTweets", res.data.tweets);
                state.commit("setCurrentTwitterTweetsMeta", res.data);
            });
        },
        async updateCurrentTweetsFilter(state, filter) {
            state.commit("setCurrentTweetsFilter", filter);
            return state.dispatch("requestCurrentTwitterTweets");
        },
        async requestCurrentTwitterTags(state){
            return twitterService.getTags().then(res => {
                state.commit("setCurrentTwitterTags", res.data);
            });
        },
        async addTwitterTag(state, tag){
            return twitterService.addTag(tag).then(async (res) => {
                await state.dispatch('requestCurrentTwitterTags');
            });
        },
        async addTwitterUser(state, payload){
            return twitterService.addUsers(payload.usernames, payload.tags).then(async (res) => {
                //state.dispatch('requestCurrentTwitterUsers');
                return res
            });
        },
        async addTwitterTagUserConnection(state, payload){
            return twitterService.addTagUserConnection(payload.tagId, payload.userId).then(res => {
                //vuex state?
            });
        },
        async delTwitterTagUserConnection(state, payload){
            return twitterService.delTagUserConnection(payload.tagId, payload.userId).then(res => {
                //vuex state?
            });
        },
        async requestTwitterTweets(state, {
            include_retweets = 1,
            deleted = 0,
            query = null,
            limit = 2000,
            page = null,
            user_ids = null
        }) {
            return twitterService.getTweets({include_retweets, deleted, query, limit, page, user_ids}).then(res => {
                res.data.tweets.forEach(tweet => {
                    if(tweet.added != null) tweet.added = new Date(tweet.added);
                    if(tweet.checked != null) tweet.checked = new Date(tweet.checked);
                    if(tweet.created != null) tweet.created = new Date(tweet.created);
                    if(tweet.deleted != null) tweet.deleted = new Date(tweet.deleted);
                });
                return res
            });
        },

    }
};
