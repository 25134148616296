import axios from "axios";
import {authHeader} from '../_helpers';
import config from 'config';
import store from "../_store/store";

export const twitterService = {
    getUsers,
    addUsers,
    delUser,
    getTweets,
    getTweet,
    getStats,
    getTags,
    addTag,
    addTagUserConnection,
    delTagUserConnection
};

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        //if(error.response.data.msg === "Token has expired" || error.response.data.msg === "Missing Authorization Header"){
        store.dispatch('account/logout').then(() => {
            console.log("logged out, need to redirect");
            location.reload(true);
            //router.push('/login');
        });
        return error;
        //}
    } else {
        return Promise.reject(error);
    }
});

function axiosConfig(merge) {
    return {
        headers: authHeader(),
        ...merge
    }
}

function getStats() {
    return axios.get(config.apiUrl + "/stats", axiosConfig());
}

function getUsers() {
    return axios.get(config.apiUrl + "/twitter/users", axiosConfig());
}

function addUsers(usernames, tags) {
    usernames = usernames.replaceAll(' ','');
    usernames = usernames.replaceAll('@','');
    return axios.put(config.apiUrl + "/twitter/users", {"usernames":usernames, "tags":tags}, axiosConfig());
}

function delUser(userId) {
    return axios.delete(  config.apiUrl+`/twitter/users/${userId}`, axiosConfig());
}

function getTweets(params) {
    let addHeaders;
    addHeaders = {
        params: {
            ...params
        }
    };
    return axios.get(config.apiUrl + "/twitter/tweets", axiosConfig(addHeaders));
}

function getTweet(tweetId) {
    return axios.get(config.apiUrl + `/twitter/tweets/${tweetId}`, axiosConfig());
}

function getTags() {
    return axios.get(config.apiUrl + "/twitter/tags", axiosConfig());
}

function addTag(tag) {
    return axios.put(config.apiUrl + "/twitter/tags", tag, axiosConfig());
}

function addTagUserConnection(tagId, userId) {
    return axios.put(  config.apiUrl+`/twitter/tags/${tagId}/users/${userId}`, {}, axiosConfig());
}

function delTagUserConnection(tagId, userId) {
    return axios.delete(  config.apiUrl+`/twitter/tags/${tagId}/users/${userId}`, axiosConfig());
}
