import 'core-js/stable'
import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro'
//import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import {router} from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
//import { freeSet, brandSet } from '@coreui/icons'
import store from './_store/store'
import i18n from './i18n.js'
import 'leaflet/dist/leaflet.css';

import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
Vue.use(CoreuiVuePro);
Vue.prototype.$log = console.log.bind(console);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
});
