import {trackerService} from "@/_services/tracker.service";

export const tracker = {
  state: {
    tracker: [],
    trackerGroups: [],
    currentDataLogs: [],
    currentPositions: [],
    currentPositionsOverview: [],
    trackerLoading: true,
    trackerDataDatetime: null,
    currentDataLogsLoading: true,
    currentPositionsLoading: true,
    currentPositionsOverviewLoading: true,
  },
  mutations: {
    setTracker(state, payload){
      state.tracker = payload;
      state.trackerLoading = false;
      state.trackerDataDatetime = new Date();
    },
    setTrackerGroups(state, payload){
      state.trackerGroups = payload;
    },
    setCurrentTrackerDataLogs(state, payload) {
      state.currentDataLogs = payload;
      state.currentDataLogsLoading = false
    },
    setCurrentPositionsOverview(state, payload) {
      state.currentPositionsOverview = payload.positions;
      state.currentPositionsOverviewLoading = false
    },
    addCurrentPositions(state, payload) {
      state.currentPositions = payload.positions;
      state.currentPositionsLoading = false
    },
    activateTrackerLoading(state) {
      state.trackerLoading = true
    },
    activateCurrentDataLogsLoading(state) {
      state.currentDataLogsLoading = true
    },
    activateCurrentPositionsLoading(state) {
      state.currentPositionsLoading = true
    },
    activateCurrentPositionsOverviewLoading(state) {
      state.currentPositionsOverviewLoading = true
    },
  },
  getters: {
    getTracker: state => state.tracker,
    getTrackerLoading: state => state.trackerLoading,
    getTrackerDataDatetime: state => state.trackerDataDatetime,
    getTrackerGroups: state => state.trackerGroups,
    getCurrentPositionsOverview: state => state.currentPositionsOverview,
    getCurrentPositions: state => state.currentPositions,
    getCurrentDataLogs: state => state.currentDataLogs,
    getCurrentDataLogsLoading: state => state.currentDataLogsLoading,
    getCurrentPositionsLoading: state => state.currentPositionsLoading,
    getCurrentPositionsOverviewLoading: state => state.currentPositionsOverviewLoading
  },
  actions: {
    async requestTracker(state) {
      state.commit("activateTrackerLoading");
      return trackerService.getTracker().then(res => {
        state.commit("setTracker", res.data);
      });
    },
    async addTracker(state, tracker){
      return trackerService.addTracker(tracker).then(async (res) => {
        await state.dispatch('requestTracker');
      });
    },
    async requestTrackerGroups(state) {
      return trackerService.getTrackerGroups().then(res => {
        state.commit("setTrackerGroups", res.data);
      });
    },
    async addTrackerGroup(state, group){
      return trackerService.addTrackerGroup(group).then(async (res) => {
        await state.dispatch('requestTrackerGroups');
      });
    },
    async requestCurrentDataLogs(state) {
      state.commit("activateCurrentDataLogsLoading");
      return trackerService.getDatalogs().then(res => {
        state.commit("setCurrentTrackerDataLogs", res.data);
      });
    },
    async requestCurrentPositions(state, tracker_id) {
      state.commit("activateCurrentPositionsLoading");
      return trackerService.getPositions(tracker_id).then(res => {
        res.data.positions.forEach(pos => {
          if(pos.received != null) pos.received = new Date(pos.received.replace(/ /g,"T")+'+0000');
          if(pos.recorded != null) pos.recorded = new Date(pos.recorded.replace(/ /g,"T")+'+0000');
        });
        state.commit("addCurrentPositions", res.data);
      });
    },
  }
};
